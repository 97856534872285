import React from "react"
import { ToastProvider } from 'react-toast-notifications'

import { Head,  TableContainer } from './index.styled'

import NewslettersTable from "./components/Table"

const NewsletterPage = () => {
  return <div>
    <ToastProvider placement="bottom-right">
      <Head>
        <h1>Newsletters</h1>
      </Head>

      <TableContainer>
        <NewslettersTable />
      </TableContainer>
    </ToastProvider>
  </div>
}

export default NewsletterPage
