import React, { useState, useEffect } from "react"
import { ToastProvider } from 'react-toast-notifications'

import { Head, AddBtn, TableContainer, Pagination, Prev, Next } from './index.styled'

import { GetTableValuesContext } from '../../context/CategoryContext'

import AddCategoryModal from "./components/Modal/addCategoryModal"
import CategoriesTable from "./components/Table"
import UpdateCategoryModal from "./components/Modal/updateCategoryModal"

const CategoryPage = () => {
  const [modalIsOpen, setIsOpen] = useState(false)

  const [updateModalIsOpen, setUpdateModalIsOpen] = useState(false)

  const [categoryId, setCategoryId] = useState('')
  const [category, setCategory] = useState(null)
  const [categories, setCategories] = useState([])

  return <div>
    <GetTableValuesContext.Provider value={{ categories, setCategories,  setIsOpen, setUpdateModalIsOpen, categoryId, setCategoryId, category, setCategory }}>
      <ToastProvider placement="bottom-right">
        <Head>
          <h1>Categories</h1>
          <AddBtn onClick={() => {setIsOpen(true)}}>Add Category</AddBtn>
          <AddCategoryModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen}/>
          <UpdateCategoryModal updateModalIsOpen={updateModalIsOpen} setUpdateModalIsOpen={setUpdateModalIsOpen}/>
        </Head>

        <TableContainer>
          <CategoriesTable />
        </TableContainer>
      </ToastProvider>
    </GetTableValuesContext.Provider>
  </div>
}

export default CategoryPage