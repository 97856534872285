import React, { useEffect, useContext} from 'react'
import Modal from 'react-modal'
import { useForm } from "react-hook-form"
import { useToasts } from 'react-toast-notifications'

import { InputWrapper, Input, Form, CheckBoxWrapper } from './updateMainBannerModal.styled'

import useBanner from '../../../../hooks/useBanner'

import { GetBannerImagesContext } from '../../../../context/BannerContext'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    minHeight: '35%',
    display: 'flex',
    flexDirection: 'column'
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.55)'
  }
}

const UpdateMainBannerModal = ({ updateModalIsOpen, setUpdateModalIsOpen }) => {
  const { addToast } = useToasts()
  const {  register, handleSubmit, formState: { errors }, setValue } = useForm()

  const { UpdateHomeMainBanner, GetHomeMainBannerById } = useBanner(addToast)

  const { mainBanner, mainBannerId } = useContext(GetBannerImagesContext)

  useEffect(() => {
    GetHomeMainBannerById(mainBannerId)
  }, [updateModalIsOpen])

  useEffect(() => {
    if(mainBanner) {
      const { id, imageUrl, redirectUrl, active, position } = mainBanner

      setValue('id', id)
      setValue('imageUrl', imageUrl)
      setValue('redirectUrl', redirectUrl)
      setValue('active', active)
      setValue('position', position)
    }
   }, [mainBanner])

  return <Modal
            isOpen={updateModalIsOpen}
            onRequestClose={() => {setUpdateModalIsOpen(false)}}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="Update Banner"
          >
            <h2>Update Banner</h2>
            {!mainBanner ? <p>Loading..</p> :            
              <Form onSubmit={handleSubmit(UpdateHomeMainBanner)}>
                <InputWrapper>
                    <Input 
                      placeholder="Image URL*"
                      {...register("imageUrl", {
                        required: "Paste image url here"
                      })}
                    />
                    {errors.imageUrl && <p>{errors.imageUrl.message}</p>}
                </InputWrapper>

                <InputWrapper>
                    <Input 
                      placeholder="Redirect URL*"
                      {...register("redirectUrl", {
                        required: "Enter Redirect url here"
                      })}
                    />
                    {errors.redirectUrl && <p>{errors.redirectUrl.message}</p>}
                </InputWrapper>

                <InputWrapper>
                    <Input 
                      type="number"
                      placeholder="Banner position*"
                      {...register("position", {
                        required: "Enter position"
                      })}
                    />
                    {errors.position && <p>{errors.position.message}</p>}
                </InputWrapper>

                <CheckBoxWrapper>
                  <div>
                    <input type="checkbox" {...register("active")}/>
                    <label>Active</label>
                  </div>  
                </CheckBoxWrapper>

                <input value="Update" type="submit"/>
              </Form>}
          </Modal>
}

export default UpdateMainBannerModal

