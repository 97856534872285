import styled, { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize'
 
const GlobalStyle = createGlobalStyle`
  ${normalize}

  * {
    box-sizing: border-box;
    font-weight: 400;
  }

  body {
    background-color: ${props => props.theme.colors.bg};
    margin: 0;
    padding: 0;
  }
  
  body, button, h1, h2, h3, h4, h5, h6, p {
    margin: 0;
    padding: 0;
    font-family: 'Karla', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 500;
  }


  button {
    background-color: ${props => props.theme.colors.bg};
  }

  button:hover{
      cursor: pointer;
  }
`;

export const Spinner = styled.div`
  width: 20px;
  height: 20px;
  border: 2px solid none;
  border-right: 2px solid white;
  border-radius: 50%;
  animation: spinner 1s linear infinite;
  box-sizing: border-box; 
  margin: 0 auto;

  @keyframes spinner {
    from {
      transform: rotate(0deg);
    } to {
      transform: rotate(360deg);
    }
  }
`;

export const LineLoader = styled.div`
  width: 100%;
  height: 3px;
  position: relative;
  overflow: hidden;
  background-color: #ddd;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;

  ::before{
    content: "";
    position: absolute;
    left: -50%;
    height: 3px;
    width: 40%;
    background-color: ${props => props.theme.colors.coa};
    -webkit-animation: lineAnim 1s linear infinite;
    -moz-animation: lineAnim 1s linear infinite;
    animation: lineAnim 1s linear infinite;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
  }

  @keyframes lineAnim {
    0% {
        left: -40%;
    }
    50% {
        left: 20%;
        width: 80%;
    }
    100% {
        left: 100%;
        width: 100%;
    }
  }
`;
 
export default GlobalStyle;