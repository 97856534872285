import styled from "styled-components"

export const Head = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  
  h1{
    color:${props => props.theme.colors.darkGrey};
  }
`;

export const AddBtn = styled.button`
    outline: none;
    border: none;
    border: 1px solid ${props => props.theme.colors.darkGrey};
    padding: 0 1rem;
    border-radius: 3px;
    color: ${props => props.theme.colors.darkGrey};
    transition: 0.1s background-color ease-in;

    :hover{
      background-color: ${props => props.theme.colors.darkGrey};
      color: ${props => props.theme.colors.white};
    }
`;


export const TableContainer = styled.div`
  margin-top: 2rem;
`;

export const Pagination = styled.div`
  margin-top: 2rem;
  height: 50px;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Prev = styled.button`
  border: none;
  outline: none;
  width: 100px;
  height: 60%;
  margin: 0 2rem;
  border-radius: 3px;
  border: 1px solid ${props => props.theme.colors.grey};
`;

export const Next = styled(Prev)`
  
`;