import React, { useEffect, useState } from "react"
import { ToastProvider, useToasts } from 'react-toast-notifications'

import { Head, AddBtn, TableContainer, Pagination, Prev, Next } from './index.styled'

import AddDealModal from "./components/Modal/addDealModal"
import UpdateDealModal from "./components/Modal/updateDealModal"
import DealsTable from "./components/Table"

import { GetTableValuesContext } from '../../context/DealContext'

import useDeal from "../../hooks/useDeal"
import useStore from "../../hooks/useStore"
import useCategory from "../../hooks/useCategory"

const DealPage = () => {
  const [modalIsOpen, setIsOpen] = useState(false)
  const [updateModalIsOpen, setUpdateModalIsOpen] = useState(false)

  const [dealId, setDealId] = useState('')
  const [deal, setDeal] = useState(null)
  const [deals, setDeals] = useState([])
  const [dealLastPage, setDealLastPage] = useState(0)
  const [filterApplied, setFilterApplied] = useState(false)

  const { addToast } = useToasts()

  const { GetStoreNames, storeNames } = useStore(addToast)
  const { GetCategoryNames, categoryNames } = useCategory(addToast)


  useEffect(() => {
    GetStoreNames()
    GetCategoryNames()
  }, [])

  return <div>
    <GetTableValuesContext.Provider value={{ deals, setDealLastPage, setDeals, setIsOpen, setUpdateModalIsOpen, dealId, setDealId, deal, setDeal, storeNames, categoryNames, filterApplied, setFilterApplied }}>
    <ToastProvider placement="bottom-right">
      <Head>
        <h1>Deals</h1>
        <div>
          <AddBtn onClick={() => {
            setIsOpen(true)
          }}>Add Deal</AddBtn>
        </div>

        <AddDealModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen}/>
        <UpdateDealModal updateModalIsOpen={updateModalIsOpen} setUpdateModalIsOpen={setUpdateModalIsOpen}/>
      </Head>

      <TableContainer>
        <DealsTable />
      </TableContainer>
      </ToastProvider>
    </GetTableValuesContext.Provider>
  </div>
}

export default DealPage
