import toast from 'react-hot-toast'

export const customToast = ({ message, type }) => {
    toast(message, {
        type,
        duration: 3000,
        position: 'top-center',
        style: {
            borderRadius: '4px',
            textAlign: 'center'
        }
    })
}