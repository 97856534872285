import React, { useEffect, useState } from "react"
import { useTable, useGlobalFilter, usePagination} from 'react-table'
import { useContext } from 'react'
import { useToasts } from 'react-toast-notifications'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import styled from 'styled-components'

import { TableActionButton, CellContainer, RedSignal, Pagination, Next, Prev } from "./index.styled"

import { GetTableValuesContext } from '../../../../context/DealContext'

import useDeal from "../../../../hooks/useDeal"
import { GlobalFilter } from "./GlobalFilter"

import './table.css'

const CopyBtn = styled.div`
  border: none;
  background: #00a47d;
  color: white;
  padding: 0.2rem 1rem;
  border-radius: 25px;
  /* width: 50%; */
  margin: 0 auto;
  cursor: pointer;
`;

const DealsTable = () => {
  const { addToast } = useToasts()

  const { deals, setUpdateModalIsOpen, setDealId } = useContext(GetTableValuesContext)
  const { getTableDeals } = useDeal(addToast)
  
  useEffect(() => {
    getTableDeals()
  }, [])


  const data = React.useMemo(() => deals, [deals])

  const CopyC = ({url}) => {
    const [copied, setCopied] = useState(false)
    
    const copyText = async (text) => {
      await navigator.clipboard.writeText(text)
      setCopied(true)
      setTimeout(() => {
        setCopied(false)
      }, 2000)
    }

    return <CopyBtn onClick={async() => { await copyText(url) }}>{copied ? 'Copied': 'Copy'}</CopyBtn>
  }

  const columns = React.useMemo(() => [{
    Header: 'Title',
    accessor: 'title',
  },{
      Header: 'Image',
      accessor: 'imageUrl', // accessor is the "key" in the data,
      Cell: ({ row }) => {
        return <img src={row.original.imageUrl} />
      }
    },{
      Header: 'Short Url',
      accessor: 'sUrl',
      Cell: ({ row }) => {
        return <CopyC url={row.original.sUrl}/>
      }
    },{
      Header: 'Actual Price',
      accessor: 'actualPrice',
    },{
      Header: 'Offer Price',
      accessor: 'offerPrice',
    },{
      Header: 'Redirect URL',
      accessor: 'redirectUrl',
      Cell: ({row}) => {
        return <a style={{ color: 'blue' }} href={row.original.redirectUrl} target="_blank">URL</a> 
      }
    },{
      Header: 'Expires',
      accessor: 'expires',
      Cell: ({row}) => {
        return new Date(row.values.expires).toDateString() 
      }
    },{
      Header: 'Store',
      accessor: 'store',
    },{
      Header: 'Hot',
      accessor: 'hot',
      Cell: ({row}) => {
        return <CellContainer>
          {row.original.hot && <RedSignal />}
        </CellContainer> 
      }
    },{
      Header: 'Action',
      Cell: ({row}) => {
        return <TableActionButton onClick={() => {
          setDealId(row.original.id)
          setUpdateModalIsOpen(true)
      }}>Edit</TableActionButton>
      }
    }
  ], [deals])

  const { getTableProps, getTableBodyProps, nextPage, previousPage, headerGroups, rows, page, prepareRow, setGlobalFilter, state, setPageSize } = useTable({ columns, data }, useGlobalFilter, usePagination)

  const { globalFilter } = state

  useEffect(() => {setPageSize(20)}, [])

  return <>
    <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter}/>
    <table {...getTableProps()} className="content-table-deals">
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {/* {rows.length === 0 && <h3 style={{textAlign: "center", margin: '1rem 0'}}>No data found</h3>} */}
            {page.map(row => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <td {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
        <Pagination>
          <Prev onClick={() => previousPage()}>Previous</Prev>
          <Next onClick={() => nextPage()}>Next</Next>
        </Pagination>
  </>
  
}

export default DealsTable