import styled from "styled-components"

export const Table = styled.table`
  width: 100%;
  border: 1px solid ${props => props.theme.colors.lightGrey};
  padding: 0;
  margin: 0;
`;

export const THead = styled.thead`
  background: ${props => props.theme.colors.navyBlue};
  color: ${props => props.theme.colors.white};
  height: 35px;

  display: flex;
  align-items: center;
  margin: 0;
  justify-content: center;

  th {
    font-weight: 500;
  }
`;

export const TBody = styled.tbody`

`;

export const TableRow = styled.tr`
    height: auto;
    text-align: center;
    font-size: 0.9rem;
    color: ${props => props.theme.colors.darkGrey};

    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;

    :nth-child(even){
      background-color: #efefef;
    }

    padding: 0.2rem 0;  

    td {
      padding: 0 1rem;
      max-width: 300px;
      max-height: 100px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    img {
      width: 50%;
      height: 100%;
      object-fit: contain;
      overflow: hidden;
    }
`;

export const TableActionButton = styled.button`
  border: none;
  outline: none;
  text-decoration: underline;
`;

export const GreenSignal = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #4BB543;
`;

export const CellContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const RedSignal = styled(GreenSignal)`
  background-color: #FC100D;
`;

export const TrendingSignal = styled(GreenSignal)`
  background: #833ab4;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #fcb045, #fd1d1d, #833ab4);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #fcb045, #fd1d1d, #833ab4); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
`;

export const Pagination = styled.div`
  margin-top: 2rem;
  height: 50px;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Prev = styled.button`
  border: none;
  outline: none;
  width: 100px;
  height: 60%;
  margin: 0 2rem;
  border-radius: 3px;
  border: 1px solid ${props => props.theme.colors.grey};
`;

export const Next = styled(Prev)`
  
`;


export const TableContainer = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`;

export const CopyBtn = styled.div`
  border: none;
  color: #252641;
  padding: 0.2rem 1rem;
  border-radius: 4px;
  width: 100px;
  margin: 0 auto;
  cursor: pointer;
  font-weight: 500;
  text-decoration: underline;
`;