export const theme = {
  colors: {
    coa: "#CE1313",
    dark: "#393E46",
    light: "#393E46",
    bg: "#f7f7f7",
    white: "#ffffff",
    lightCoa: "#BC6FF1",
    card: "#FEFEFF",
    header: "#CE1313",
    grey: "#6e6d7a",
    black: "#000000",
    lightGrey: "#d6d9dc",
    errorAlert: "#fff2f0",
    darkGrey: "#393e46",
    success: "#4BB543",
    newGreen: "#00A47D",
    navyBlue: "#252641"
  },
  fontSizes: {
    small: "1em",
    medium: "2em",
    large: "3em"
  }
};

