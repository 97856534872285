import React, { useEffect, useState, useContext } from 'react'
import { useTable, usePagination } from 'react-table'
import { useQuery } from 'react-query'
import { fetchLinkData } from './fetchData'

import {CellContainer, TableActionButton, CopyBtn } from './LinkTable.styled'
import { GetTableValuesContext } from '../../../../context/LinkContext'

import Pagination from './Pagination'

import './table.css'
import { useTableReducer } from './TableContext'

const CopyC = ({url}) => {
  const [copied, setCopied] = useState(false)
  
  const copyText = async (text) => {
    await navigator.clipboard.writeText(text)
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 2000)
  }

  return <CopyBtn onClick={async() => { await copyText(url) }}>{copied ? 'Copied': 'Copy'}</CopyBtn>
}

const LinksTable = () => {
  const [{ queryPageIndex, queryPageSize, totalCount }, dispatch] = useTableReducer()
  const { setUpdateModalIsOpen, setGenerateModalIsOpen, setLinkId } = useContext(GetTableValuesContext)

  const { isLoading, error, data, isSuccess } = useQuery(
    ["tablelinks", queryPageIndex, queryPageSize],
    () => fetchLinkData(queryPageIndex, queryPageSize),
    {
      keepPreviousData: true,
      staleTime: Infinity
    }
  )

  const GROUPED_COLUMNS = [
    {
      Header: 'Info',
      columns: [
        {
          Header: 'Store',
          accessor: 'storeName'
        },
        {
          Header: 'Network',
          accessor: 'partnerName',
          disableGlobalFilter: true
        },
        {
          Header: 'Active',
          accessor: 'active',
          Cell: ({row}) => {
            return <CellContainer>
            {row.original.active && <p>✅</p>}
            </CellContainer> 
          }
        }
      ]
    },
    {
      Header: 'Actions',
      columns: [
        {
          Header: 'Home affiliate link',
          Cell: ({row}) => {
            return <CopyC url={row.original.affiliateUrl}/>
          }
        },
        {
          Header: 'Deep link',
          Cell: ({row}) => {
            return <TableActionButton onClick={() => {
              setLinkId(row.original.id)
              setGenerateModalIsOpen(true)
          }}>Generate</TableActionButton>
          }
        },
        {
          Header: 'Edit Link',
          Cell: ({row}) => {
            return <TableActionButton onClick={() => {
              setLinkId(row.original.id)
              setUpdateModalIsOpen(true)
          }}>Edit</TableActionButton>
          }
        }
      ] 
    }
  ]

  const columns = React.useMemo(() => GROUPED_COLUMNS, [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data: isSuccess ? data?.links : [],
      initialState: {
        pageIndex: queryPageIndex,
        pageSize: queryPageSize
      },
      manualPagination: true,
      pageCount: isSuccess ? Math.ceil(totalCount / queryPageSize) : null
    },
    usePagination
  )

  useEffect(() => {
    dispatch({ type: 'PAGE_CHANGED', payload: pageIndex });
  }, [pageIndex])

  useEffect(() => {
    dispatch({ type: 'PAGE_SIZE_CHANGED', payload: pageSize });
    gotoPage(0)
  }, [pageSize, gotoPage]);

  useEffect(() => {
    if (data?.count) {
      dispatch({
        type: 'TOTAL_COUNT_CHANGED',
        payload: data.count
      })
    }
  }, [data?.count])

  if (error) {
    return <p>Error</p>;
  }

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      {isSuccess ? (
        <>
        <Pagination 
          canPreviousPage={canPreviousPage} 
          canNextPage={canNextPage} 
          pageCount={pageCount} 
          pageIndex={pageIndex} 
          pageOptions={pageOptions} 
          pageSize={pageSize}
          gotoPage={gotoPage}
          previousPage={previousPage}
          nextPage={nextPage}
          setPageSize={setPageSize}
        />
          <table {...getTableProps()} className="content-table">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      ) : <h1>Nothing</h1>}
    </>
  )
}

export default LinksTable