import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;


  h1 {
    margin-bottom: 2rem;
    color: ${props => props.theme.colors.navyBlue};
  }

  form {
    width: 100%;

    p {
      color: ${props => props.theme.colors.coa};
      font-size: smaller;
      margin-top: 0.2rem;
      float: right;
    }

    button {
      width: 100%;
      color: ${props => props.theme.colors.white};
      padding: 0.6rem;
      border: none;
      font-size: 1rem;
      border-radius: 4px;
      margin: 1rem 0;
      background: ${props => props.theme.colors.newGreen};


      &:hover{
          cursor: pointer;
          filter: brightness(0.9);
      }
    }
  }
`;

export const Content = styled.div`
    width: 30%;
`;

export const Input = styled.input`
  width: 100%;
  border: 1px solid ${props => props.theme.colors.lightGrey};
  padding: 0.6rem 1rem;
  color: ${props => props.theme.colors.grey};
  border-radius: 4px;

  :active, :focus {
    outline: none;
  }

`;

export const LogoContainer = styled.div`
  width: 100%;
  margin: 0rem auto;
  display: flex;
  justify-content: center;
  width: 200px;
  height: 100px;

  img {
    object-fit: contain;
    width: 300px;
  }
`;

export const InputWrap = styled.div`
  width: 100%;
  margin-bottom: 1.5rem;
`;

export const ErrorMessage = styled.div`
  width: 100%;
  padding: 0.6rem;
  /* background-color: ${props => props.theme.colors.errorAlert}; */
  color: ${props => props.theme.colors.coa};
  /* border: 1px solid #ffccc7; */
  border-radius: 2px;
  margin: 0 0 1rem 0;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Footer = styled.div`
  width: 100%;
  height: 30px;
  background-color: ${props => props.theme.colors.navyBlue};
  position: absolute;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  h4 {
    color: white;
  }
`;