import React, { useState } from "react"
import { ToastProvider } from 'react-toast-notifications'

import { Head, AddBtn, TableContainer, MainBanner, BottomBanner } from './index.styled'

import { GetBannerImagesContext } from '../../context/BannerContext'

import AddBannerModal from "./components/Modal/addBannerModal"
import AddBottomBannerModal from "./components/Modal/addBottomBannerModal"

import MainBannerTable from "./components/Table/mainBannerTable"
import BottomBannerTable from "./components/Table/bottomBannerTable"

import UpdateMainBannerModal from "./components/Modal/updateMainBannerModal"
import UpdateBottomBannerModal from "./components/Modal/updateBottomBannerModal"

const BannerPage = () => {
  const [modalIsOpen, setIsOpen] = useState(false)
  const [mainBanners, setMainBanners] = useState([])
  const [mainBannerId, setMainBannerId] = useState('')
  const [updateModalIsOpen, setUpdateModalIsOpen] = useState(false)
  const [mainBanner, setMainBanner] = useState(null)

  const [bottomBannerModalIsOpen, setBottomBannerModalIsOpen] = useState(false)
  const [bottomBanners, setBottomBanners] = useState([])
  const [bottomBannerId, setBottomBannerId] = useState('')
  const [updateBottomModalIsOpen, setUpdateBottomModalIsOpen] = useState(false)
  const [bottomBanner, setBottomBanner] = useState(null)

  return <div>
    <GetBannerImagesContext.Provider value={{ setIsOpen, mainBanners, setMainBanners, mainBannerId, setMainBannerId, updateModalIsOpen, setUpdateModalIsOpen, mainBanner, setMainBanner, setBottomBannerModalIsOpen, bottomBanners, setBottomBanners, bottomBannerId, setBottomBannerId, updateBottomModalIsOpen, setUpdateBottomModalIsOpen, bottomBanner, setBottomBanner }}>
      <ToastProvider placement="bottom-right">
        <MainBanner>
          <Head>
            <h1>Home Main Banners</h1>
            <div>
              <AddBtn onClick={() => {setIsOpen(true)}}>Add Banner</AddBtn>
            </div>
            <AddBannerModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen}/>
            <UpdateMainBannerModal updateModalIsOpen={updateModalIsOpen} setUpdateModalIsOpen={setUpdateModalIsOpen}/>
          </Head>
          <TableContainer>
            <MainBannerTable />
          </TableContainer>
        </MainBanner>

        <BottomBanner>
          <Head>
            <h1>Home Bottom Banners</h1>
            <div>
              <AddBtn onClick={() => {setBottomBannerModalIsOpen(true)}}>Add Banner</AddBtn>
            </div>
            <AddBottomBannerModal modalIsOpen={bottomBannerModalIsOpen} setIsOpen={setBottomBannerModalIsOpen}/>
            <UpdateBottomBannerModal updateModalIsOpen={updateBottomModalIsOpen} setUpdateModalIsOpen={setUpdateBottomModalIsOpen}/>
          </Head>
          <TableContainer>
            <BottomBannerTable />
          </TableContainer>
        </BottomBanner>
      </ToastProvider>
    </GetBannerImagesContext.Provider>
  </div>
}

export default BannerPage