import React, {useCallback} from 'react'
import Modal from 'react-modal'
import { useForm } from "react-hook-form"
import {useDropzone} from 'react-dropzone'
import { Line } from 'rc-progress'
import { useToasts } from 'react-toast-notifications'

import { UploadArea, Button, Form } from './uploadOffersModal.styled'

import usePoffer from '../../../../hooks/usePoffer'

import UploadIcon from '../../../../media/util-icons/upload.svg'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '25%',
    minHeight: '25%',
    display: 'flex',
    flexDirection: 'column'
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.55)'
  }
}

const ProgressBar = ({ percentage }) => {
  return <Line percent={percentage.toString()} strokeLinecap="square" strokeWidth="1.2" trailWidth="1.2" strokeColor="#4CB942" />
}

const UploadOffersModal = ({uploadModalOpen, setUploadModalOpen}) => {
  const { handleSubmit } = useForm()
  const { addToast } = useToasts()
  const { onSubmit, setFiles, uploadPercentage, selectedFilesLength, setSelectedFilesLength } = usePoffer(addToast)

  const onDrop = useCallback(acceptedFiles => {
    setFiles(acceptedFiles)
    setSelectedFilesLength(acceptedFiles.length)
  }, [])

  const {getRootProps, getInputProps} = useDropzone({ 
    onDrop,
    accept: "application/json" 
  }) 

  return <Modal
            isOpen={uploadModalOpen}
            onRequestClose={() => {setUploadModalOpen(false)}}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="Upload Offers"
          >
            <Form onSubmit={handleSubmit(onSubmit)}>
              <UploadArea {...getRootProps()}>
                <div>
                  <img alt="upload-icon" src={UploadIcon} width={50}/>
                  <h3>Click to upload or drag and drop folder here</h3>
                </div>
                <input 
                  type="file" 
                  webkitdirectory mozdirectory directory
                  multiple
                  defaultValue=""
                  {...getInputProps()}
                  name="files"
                />
              </UploadArea>
              {selectedFilesLength > 0 && <h4>{selectedFilesLength} files selected</h4>}
              <Button>{(uploadPercentage > 0 || uploadPercentage === 100) ? 'Uploading...': 'Upload'}</Button>
              {(uploadPercentage > 0 || uploadPercentage === 100) && <ProgressBar percentage={uploadPercentage}/>}              
            </Form>
          </Modal>
}

export default UploadOffersModal
