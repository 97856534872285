import styled from "styled-components"

export const Table = styled.table`
  width: 100%;
  border: 1px solid ${props => props.theme.colors.lightGrey};
  padding: 0;
  margin: 0;
`;

export const THead = styled.thead`
  background: ${props => props.theme.colors.navyBlue};
  color: ${props => props.theme.colors.white};
  height: 35px;

  display: flex;
  align-items: center;
  margin: 0;
  justify-content: center;

  th {
    font-weight: 500;
  }
`;

export const TBody = styled.tbody`

`;

export const TableRow = styled.tr`
    height: 35px;
    text-align: center;
    font-size: 0.9rem;
    color: ${props => props.theme.colors.darkGrey};

    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;

    :nth-child(even){
      background-color: #efefef;
    }

    td {
      padding: 0 1rem;
      max-width: 300px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
`;

export const TableActionButton = styled.button`
  border: none;
  outline: none;
  text-decoration: underline;
`;

export const Pagination = styled.div`
  margin-top: 2rem;
  height: 50px;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Prev = styled.button`
  border: none;
  outline: none;
  width: 100px;
  height: 60%;
  margin: 0 2rem;
  border-radius: 3px;
  border: 1px solid ${props => props.theme.colors.grey};
`;

export const Next = styled(Prev)`
  
`;