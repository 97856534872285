import axios from "axios"
import { useState, useContext } from "react"
import Endpoints from '../config/endpoints'

import { GetTableValuesContext } from '../context/CategoryContext'

const { PostCategoryEndpoint, GetCategoryNamesEndpoint, GetTableCategoriesEndpoint, GetCategoryByIdEndpoint, UpdateCategoryEndpoint } = Endpoints

const useCategory = (addToast) => {
  const [categoryNames, getCategoryNames] = useState([])
  const docsPerPage = 50

  const { setCategory, setCategories, setCategoryLastPage } = useContext(GetTableValuesContext)
  
  const PostCategory = async (data) => {
    try {
      const response = await axios({
        method: 'POST',
        url: PostCategoryEndpoint,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json', 
        },
        withCredentials: true,
        data: JSON.stringify(data)
      })

      addToast(response.data.message, { 
        appearance: 'success',
        autoDismiss: true
      })
      getTableCategories()
    }catch(err){
      addToast(err.response.data.message, { 
        appearance: 'error',
        autoDismiss: true
      })
    }
  }

  const GetCategoryNames = async () => {
    try {
      const response = await axios({
        method: 'GET',
        url: GetCategoryNamesEndpoint,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json', 
        },
        withCredentials: true
      })

      const parsedCategoryNames = response.data.map((category, index) => {
        return {
          label: category.label,
          value: category.code
        }
      })

      getCategoryNames(parsedCategoryNames)
    } catch (err) {
    }
  }

  const getTableCategories = async (page) => {
    try {
      const response = await axios({
        method: 'POST',
        url: GetTableCategoriesEndpoint,
        headers: {
          'Content-Type': 'application/json', 
        },
        withCredentials: true,
        data: JSON.stringify({ page })
      })

      const categories = response.data.categories

      setCategories(categories)
      setCategoryLastPage(Math.ceil(response.data.totalCategoriesCount / docsPerPage))

    } catch(err) {

    }
  }


  const GetCategoryById = async (storeId) => {
    try {
      if(!storeId) return;

      const response = await axios({
        method: 'GET',
        url: GetCategoryByIdEndpoint + storeId,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json', 
        },
        withCredentials: true
      })

      setCategory(response.data)
    } catch(err) {

    }
  }

  const UpdateCategory = async (data) => {
    try {
      data = {
        ...data,
        id: parseInt(data.id)
      }

      const response = await axios({
        method: 'POST',
        url: UpdateCategoryEndpoint,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json', 
        },
        withCredentials: true,
        data: JSON.stringify(data)
      })

      addToast(response.data.message, { 
        appearance: 'success',
        autoDismiss: true
      })

      getTableCategories()
    }catch(err){
      addToast(err.response.data.message, { 
        appearance: 'error',
        autoDismiss: true
      })
    }
  }



  return { PostCategory, GetCategoryNames, categoryNames, getTableCategories, GetCategoryById, UpdateCategory  }
}

export default useCategory