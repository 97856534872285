import React, { useEffect, useContext, useState} from 'react'
import Modal from 'react-modal'
import { useForm, Controller } from "react-hook-form"
import { useToasts } from 'react-toast-notifications'

import { InputWrapper, Input, Form, CheckBoxWrapper, DropDownWrapper, Left } from './updateStoreModal.styled'

import useStore from '../../../../hooks/useStore'

import { GetTableValuesContext } from '../../../../context/StoreContext'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    maxHeight: '80%',
    display: 'flex',
    flexDirection: 'column'
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.55)'
  }
}

const UpdateStoreModal = ({ updateModalIsOpen, setUpdateModalIsOpen }) => {
  const { addToast } = useToasts()

  const { control, register, handleSubmit, formState: { errors }, setValue } = useForm()

  const { GetStoreById, UpdateStore } = useStore(addToast)

  const { storeId, store, categoryNames } = useContext(GetTableValuesContext)
  
  const [selectedCategory, setSelectedCategory] = useState()

  useEffect(() => {
    GetStoreById(storeId)
  }, [storeId])

  useEffect(() => {
    if(store) {
      const { id, name, code, description, heading, pheading, about, imageUrl, storeUrl, active, popular, metaTitle, metaDescription, domain, extensionOpen, background } = store

      setValue('id', id)
      setValue('name', name)
      setValue('code', code)
      setValue('heading', heading)
      setValue('pheading', pheading)
      setValue('description', description)
      setValue('metaTitle', metaTitle)
      setValue('metaDescription', metaDescription)
      setValue('about', about)
      setValue('imageUrl', imageUrl)
      setValue('storeUrl', storeUrl)
      setValue('domain', domain)
      setValue('active', active)
      setValue('popular', popular)
      setValue('background', background)
      setValue('extensionOpen', extensionOpen)
    }
   }, [store])

  return <Modal
            isOpen={updateModalIsOpen}
            onRequestClose={() => {setUpdateModalIsOpen(false)}}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="Update Store"
          >
            <h2>Update Store</h2>
            {!store ? <p>Loading..</p> :            
              <Form onSubmit={handleSubmit(UpdateStore)}>
                <InputWrapper>
                    <Input 
                      disabled
                      {...register("id", {
                        required: "Enter a store id"
                      })}
                    />
                    {errors.id && <p>{errors.id.message}</p>}
                </InputWrapper>

                <InputWrapper>
                  <Input 
                    autoComplete="off"
                    placeholder="Store name* (eg. Reliance Digital)" 
                    {...register("name", {
                      required: "Enter a store name"
                    })} 
                  />
                  {errors.name && <p>{errors.name.message}</p>}
                </InputWrapper>

                <InputWrapper>
                  <Input 
                    autoComplete="off"  
                    placeholder="Store code* (eg. reliance-digital)" 
                    {...register("code", {
                      required: "Enter a store code"
                    })} 
                  />
                  {errors.code && <p>{errors.code.message}</p>}
                </InputWrapper>

                <InputWrapper>
                  <Input 
                    autoComplete="off"  
                    placeholder="Store heading* (eg. Boat Coupons & Offers)" 
                    {...register("heading", {
                      required: "Enter a store heading"
                    })} 
                  />
                  {errors.heading && <p>{errors.heading.message}</p>}
                </InputWrapper>

                <InputWrapper>
                  <Input 
                    autoComplete="off"  
                    placeholder="Permanent offer Store heading*" 
                    {...register("pheading", {
                      required: "Enter permanent offer store heading"
                    })} 
                  />
                  {errors.pheading && <p>{errors.pheading.message}</p>}
                </InputWrapper>

                <InputWrapper>
                  <textarea 
                    autoComplete="off"
                    placeholder="Store Description*" 
                    {...register("description", {
                      required: "Enter a store description"
                    })} 
                  />
                  {errors.description && <p>{errors.description.message}</p>}
                </InputWrapper>

                <InputWrapper>
                  <textarea 
                    autoComplete="off"
                    placeholder="About store*" 
                    {...register("about", {
                      required: "Enter about the store"
                    })} 
                  />
                  {errors.about && <p>{errors.about.message}</p>}
                </InputWrapper>

                <InputWrapper>
                  <Input 
                    autoComplete="off"
                    placeholder="Image url" 
                    {...register("imageUrl", {
                      required: "Paste image url here"
                    })} 
                  />
                  {errors.imageUrl && <p>{errors.imageUrl.message}</p>}
                </InputWrapper>

                <InputWrapper>
                  <Input 
                    autoComplete="off"
                    placeholder="Store url (with affiliate)" 
                    {...register("storeUrl", {
                      required: "Paste store url here"
                    })} 
                  />
                  {errors.storeUrl && <p>{errors.storeUrl.message}</p>}
                </InputWrapper>

                <InputWrapper>
                  <Input 
                    autoComplete="off"
                    placeholder="Store domain" 
                    {...register("domain", {
                      required: "Paste store domain here"
                    })} 
                  />
                  {errors.domain && <p>{errors.domain.message}</p>}
                </InputWrapper>

                <InputWrapper>
                  <Input 
                    autoComplete="off"
                    placeholder="Meta title*" 
                    {...register("metaTitle", {
                      required: "Enter a meta title"
                    })} 
                  />
                  {errors.metaTitle && <p>{errors.metaTitle.message}</p>}
                </InputWrapper>

                <InputWrapper>
                  <Input 
                    autoComplete="off"
                    placeholder="Meta description*" 
                    {...register("metaDescription", {
                      required: "Enter a meta description"
                    })} 
                  />
                  {errors.metaDescription && <p>{errors.metaDescription.message}</p>}
                </InputWrapper>

                <InputWrapper>
                  <Input 
                    autoComplete="off"
                    placeholder="Background color*" 
                    {...register("background", {
                      required: "Background color*"
                    })} 
                  />
                  {errors.background && <p>{errors.background.message}</p>}
                </InputWrapper>

                <CheckBoxWrapper>
                  <div>
                    <input type="checkbox" {...register("active")}/>
                    <label>Active</label>  
                  </div>

                  <div>
                    <input type="checkbox" {...register("popular")}/>
                    <label>Popular</label>  
                  </div> 

                  <div>
                    <input type="checkbox" {...register("extensionOpen")}/>
                    <label>Extension</label>  
                  </div> 
                </CheckBoxWrapper>

                <input value="Update Store" type="submit"/>
              </Form>}
          </Modal>
}

export default UpdateStoreModal

