import React, { useEffect } from 'react'
import Modal from 'react-modal'
import { useForm } from "react-hook-form"
import { useToasts } from 'react-toast-notifications'


import { InputWrapper, Input, Form, CheckBoxWrapper } from './addCategoryModal.styled'

import useCategory from '../../../../hooks/useCategory'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    minHeight: '50%',
    display: 'flex',
    flexDirection: 'column'
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.55)'
  }
}

const AddCategoryModal = ({modalIsOpen, setIsOpen}) => {
  const { addToast } = useToasts()
  const { PostCategory } = useCategory(addToast)
  
  const { register, handleSubmit, formState: { errors }, setValue } = useForm()

  useEffect(() => {
    setValue("label", "")
    setValue("code", "")
    setValue("description", "")
    setValue("imageUrl", "")
    setValue('metaTitle', "")
    setValue('metaDescription', "")
    setValue("active", true)
    setValue("popular", false)
  }, [modalIsOpen])

  return <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => {setIsOpen(false)}}
            style={customStyles}
            contentLabel="Add Coupon"
          >
            <h2>Add Category</h2>
            
            <Form onSubmit={handleSubmit(PostCategory)}>
              <InputWrapper>
                <Input 
                  autoComplete="off"
                  placeholder="Category label* (eg. Airtel Offer)" 
                  {...register("label", {
                    required: "Enter a Category label"
                  })} 
                />
                {errors.label && <p>{errors.label.message}</p>}
              </InputWrapper>

              <InputWrapper>
                <Input 
                  autoComplete="off"  
                  placeholder="Categorycode* (eg. airtel-offer)" 
                  {...register("code", {
                    required: "Enter a Categorycode"
                  })} 
                />
                {errors.code && <p>{errors.code.message}</p>}
              </InputWrapper>

              <InputWrapper>
                <textarea 
                  autoComplete="off"
                  placeholder="Category Description*" 
                  {...register("description", {
                    required: "Enter a Category description"
                  })} 
                />
                {errors.description && <p>{errors.description.message}</p>}
              </InputWrapper>

              <InputWrapper>
                <Input 
                  autoComplete="off"
                  placeholder="Image url" 
                  {...register("imageUrl", {
                    required: "Paste image url here"
                  })} 
                />
                {errors.imageUrl && <p>{errors.imageUrl.message}</p>}
              </InputWrapper>

              <InputWrapper>
                <Input 
                  autoComplete="off"
                  placeholder="Meta title*" 
                  {...register("metaTitle", {
                    required: "Enter a meta title"
                  })} 
                />
                {errors.metaTitle && <p>{errors.metaTitle.message}</p>}
              </InputWrapper>

              <InputWrapper>
                <Input 
                  autoComplete="off"
                  placeholder="Meta description*" 
                  {...register("metaDescription", {
                    required: "Enter a meta description"
                  })} 
                />
                {errors.metaDescription && <p>{errors.metaDescription.message}</p>}
              </InputWrapper>

              <CheckBoxWrapper>
                <div>
                  <input type="checkbox" defaultChecked {...register("active")}/>
                  <label>Active</label>  
                </div>

                <div>
                  <input type="checkbox" {...register("popular")}/>
                  <label>Popular</label>  
                </div> 
              </CheckBoxWrapper>

              <input value="Add Category" type="submit"/>
            </Form>
            
          </Modal>
}

export default AddCategoryModal


