import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 2rem;
  align-items: flex-end;

  h2 {
    color: ${props => props.theme.colors.darkGrey};
  }

  button {
      color: ${props => props.theme.colors.newGreen};
      border: none;
      font-size: 1rem;
      border-radius: 3px;
      background: ${props => props.theme.colors.bg};

      &:hover{
          cursor: pointer;
      }
  }
`;

export const Ruler = styled.div`
  height: 1px;
  width: 100%;
  margin: 1rem 0;
  background: ${props => props.theme.colors.lightGrey};
`;