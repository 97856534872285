import React from "react"
import styled from "styled-components"

const Input = styled.input`
  border: 1px solid #aaaaaa;
  padding: 0.5rem 1rem;
  margin: 1rem 0;
  width: 300px;
  outline: none;
`;

export const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <span>
      <Input placeholder="Search store here" value={filter || ''} onChange={e => setFilter(e.target.value)}/>
    </span>
  )
}