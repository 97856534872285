import { useState, useContext } from "react"
import axios from "axios"

import Endpoints from '../config/endpoints'

import { GetTableValuesContext } from '../context/StoreContext'

const { PostStoreEndpoint, GetStoreNamesEndpoint, GetTableStoresEndpoint, GetStoreByIdEndpoint, UpdateStoreEndpoint } = Endpoints

const useStore = (addToast) => {
  const [storeNames, setStoreNames] = useState([])
  const docsPerPage = 50

  const { setStore,setStores, setStoreLastPage, page } = useContext(GetTableValuesContext)

  const PostStore = async (data) => {
    try {

      data = {
        ...data,
        id: parseInt(data.id)
      }

      const response = await axios({
        method: 'POST',
        url: PostStoreEndpoint,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json', 
        },
        withCredentials: true,
        data: JSON.stringify(data)
      })

      addToast(response.data.message, { 
        appearance: 'success',
        autoDismiss: true
      })
      getTableStores(page)
    }catch(err){
      addToast(err.response.data.message, { 
        appearance: 'error',
        autoDismiss: true
      })
    }
  }

  const GetStoreNames = async (search=false) => {
    try {
      const response = await axios({
        method: 'GET',
        url: GetStoreNamesEndpoint,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json', 
        },
        withCredentials: true
      })

      const parsedStoreNames = response.data.map((store, index) => {
        if(search) {
          return {
            name: store.name,
            value: store.code
          }  
        }
        return {
          label: store.name,
          value: store.code
        }
      })

      setStoreNames(parsedStoreNames)
    } catch (err) {

    }
  }

  const getTableStores = async (page) => {
    try {
      const response = await axios({
        method: 'POST',
        url: GetTableStoresEndpoint,
        headers: {
          'Content-Type': 'application/json', 
        },
        withCredentials: true,
        data: JSON.stringify({ page })
      })

      const stores = response.data.stores

      if(stores.length > 0) {
        setStores(stores)
      } else {
        setStores([])
        setStoreLastPage(1)
      } 
    } catch(err) {

    }
  }


  const GetStoreById = async (storeId) => {
    try {
      if(!storeId) return;

      const response = await axios({
        method: 'GET',
        url: GetStoreByIdEndpoint + storeId,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json', 
        },
        withCredentials: true
      })

      setStore(response.data)
    } catch(err) {

    }
  }

  const UpdateStore = async (data) => {
    try {
      data = {
        ...data,
        id: parseInt(data.id)
      }

      const response = await axios({
        method: 'POST',
        url: UpdateStoreEndpoint,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json', 
        },
        withCredentials: true,
        data: JSON.stringify(data)
      })

      addToast(response.data.message, { 
        appearance: 'success',
        autoDismiss: true
      })


      getTableStores(page)
    }catch(err){
      addToast(err.response.data.message, { 
        appearance: 'error',
        autoDismiss: true
      })
    }
  }
  
  return { PostStore, GetStoreNames, getTableStores, GetStoreById, UpdateStore, storeNames }
}

export default useStore