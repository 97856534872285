const BASE_URL = process.env.REACT_APP_BASEURL

const endpoints = {
  LoginAdmin: `${BASE_URL}/admin/login`,
  CurrentAdmin: `${BASE_URL}/admin/current`,
  LogoutAdmin: `${BASE_URL}/admin/logout`,

  UploadFile: `${BASE_URL}/json/upload`,

  // STORES
  PostStoreEndpoint: `${BASE_URL}/store/create`,
  GetStoreNamesEndpoint: `${BASE_URL}/store/names`,
  GetTableStoresEndpoint: `${BASE_URL}/store/table`,
  GetStoreByIdEndpoint: `${BASE_URL}/store/one/`,
  UpdateStoreEndpoint: `${BASE_URL}/store/update`,
  GetLastStoreIdEndpoint: `${BASE_URL}/store/lastId`,

  // Categories
  PostCategoryEndpoint: `${BASE_URL}/category/create`,
  GetCategoryNamesEndpoint: `${BASE_URL}/category/names`,
  GetTableCategoriesEndpoint: `${BASE_URL}/category/table`,
  GetCategoryByIdEndpoint: `${BASE_URL}/category/one/`,
  UpdateCategoryEndpoint: `${BASE_URL}/category/update`,

  // Coupon
  PostCouponEndpoint: `${BASE_URL}/coupon/create`,
  UpdateCouponEndpoint: `${BASE_URL}/coupon/update`,
  UploadCouponsEndpoint: `${BASE_URL}/coupon/upload`,
  GetTableCouponsEndpoint: `${BASE_URL}/coupon/table`,
  GetCouponByIdEndpoint: `${BASE_URL}/coupon/one/`,
  GetTableCouponsWithFilterEndpoint: `${BASE_URL}/coupon/tablefilter`,

  // Deals
  PostDealEndpoint: `${BASE_URL}/deal/imageUpload`,
  UpdateDealEndpoint: `${BASE_URL}/deal/update`,
  UploadDealsEndpoint: `${BASE_URL}/deal/upload`,
  GetTableDealsEndpoint: `${BASE_URL}/deal/table`,
  GetDealByIdEndpoint: `${BASE_URL}/deal/one/`,
  GetTableDealsWithFilterEndpoint: `${BASE_URL}/deal/tablefilter`,

  // Offers
  PostOfferEndpoint: `${BASE_URL}/offer/create`,
  UpdateOfferEndpoint: `${BASE_URL}/offer/update`,
  UploadOffersEndpoint: `${BASE_URL}/offer/upload`,
  GetTableOffersEndpoint: `${BASE_URL}/offer/table`,
  GetOfferByIdEndpoint: `${BASE_URL}/offer/one/`,

  // POffers
  PostPOfferEndpoint: `${BASE_URL}/poffer/create`,
  UpdatePOfferEndpoint: `${BASE_URL}/poffer/update`,
  UploadPOffersEndpoint: `${BASE_URL}/poffer/upload`,
  GetTablePOffersEndpoint: `${BASE_URL}/poffer/table`,
  GetPOfferByIdEndpoint: `${BASE_URL}/poffer/one/`,

  // Banner
  // Main Banner
  PostMainBannerEndpoint: `${BASE_URL}/homemainbanner/create`,
  MainBannerTableEndpoint: `${BASE_URL}/homemainbanner/table`,
  GetHomeMainBannerByIdEndpoint: `${BASE_URL}/homemainbanner/one/`,
  UpdateHomeMainBannerEndpoint: `${BASE_URL}/homemainbanner/update`,
  // Bottom Banner
  PostBottomBannerEndpoint: `${BASE_URL}/homebottombanner/create`,
  BottomBannerTableEndpoint: `${BASE_URL}/homebottombanner/table`,
  GetHomeBottomBannerByIdEndpoint: `${BASE_URL}/homebottombanner/one/`,
  UpdateHomeBottomBannerEndpoint: `${BASE_URL}/homebottombanner/update`,

  // Newsletter
  GetNewsletterDataEndpoint: `${BASE_URL}/newsletter/table`
}

export default endpoints