import { useState, useContext } from 'react'
import axios from "axios"

import Endpoints from '../config/endpoints'

import { GetTableValuesContext } from '../context/CouponContext'

const { PostCouponEndpoint, UpdateCouponEndpoint, UploadCouponsEndpoint, GetTableCouponsEndpoint, GetCouponByIdEndpoint, GetTableCouponsWithFilterEndpoint } = Endpoints

const useCoupon = (addToast) => {
  const [files, setFiles] = useState([])
  const [uploadPercentage, setUploadPercentage] = useState(0)
  const [selectedFilesLength, setSelectedFilesLength] = useState(0)

  const { setCoupon,setCoupons, setCouponLastPage, page, setFilterApplied, pageSize, setPageCount } = useContext(GetTableValuesContext)

  const docsPerPage = 50

  const PostCoupon = async (data) => {
    try {
      data = {
        ...data,
        score: parseInt(data.score),
        storeCode: data.storeCode.value,
        categoryCode: data.categoryCode.value
      }

      const response = await axios({
        method: 'POST',
        url: PostCouponEndpoint,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json', 
        },
        withCredentials: true,
        data: JSON.stringify(data)
      })

      addToast(response.data.message, { 
        appearance: 'success',
        autoDismiss: true
      })

      getTableCoupons()
    }catch(err){
      addToast(err.response.data.message, { 
        appearance: 'error',
        autoDismiss: true
      })
    }
  }

  const onSubmit = async () => {
    try {
      if(!files.length) {
        addToast("Select some files to upload", { 
          appearance: 'error',
          autoDismiss: true
        })
        return
      }
      
      const formData = new FormData()

      for(let i = 0; i < files.length; i++) {  
        formData.append('files', files[i]);
      }

      const response = await axios({
        method: 'POST',
        url: UploadCouponsEndpoint,
        headers: {
          'Content-Type': 'multipart/form-data', 
        },
        withCredentials: true,
        data: formData,
        onUploadProgress: (progressEvent) => {
          setUploadPercentage(50)
        },
      })

      setUploadPercentage(100)
      setSelectedFilesLength(0)

      setTimeout(() => {
        setUploadPercentage(0)
      }, [100])

      addToast(response.data.message, { 
        appearance: 'success',
        autoDismiss: true
      })

      getTableCoupons(page)
    } catch(err) {
      addToast(err.response.data.message, { 
        appearance: 'error',
        autoDismiss: true
      })
      setUploadPercentage(100)
      setSelectedFilesLength(0)

      setTimeout(() => {
        setUploadPercentage(0)
      }, [100])
    }
  }

  const getTableCoupons = async (page = 1, offset) => {
    try {
      const response = await axios({
        method: 'POST',
        url: GetTableCouponsEndpoint,
        headers: {
          'Content-Type': 'application/json', 
        },
        withCredentials: true,
        data: JSON.stringify({ page, offset })
      })

      const coupons = response.data.coupons

      if(coupons.length > 0) {
        let parsedArray = coupons.map((cp) => {
          let resObj = {
            ...cp,
            store: cp.store.name,
            category: cp.category.label
          }

          return resObj
        })

        setCoupons(parsedArray)
        const pageCt = Math.ceil(response.data.totalCouponsCount / pageSize)
        setPageCount(pageCt)
      } else {
        setCoupons([])
        setCouponLastPage(1)
      } 
    } catch(err) {
      addToast(err.response.data.message, { 
        appearance: 'error',
        autoDismiss: true
      })
    }
  }

  const GetCouponsWithFilter = async (data) => {
    try {
      const { storeCode, categoryCode, popular } = data
      setFilterApplied(true)
      
      const response = await axios({
        method: 'POST',
        url: GetTableCouponsWithFilterEndpoint,
        headers: {
          'Content-Type': 'application/json', 
        },
        withCredentials: true,
        data: JSON.stringify({ 
          page: 1,
          storeCode: storeCode && storeCode.value, 
          categoryCode: categoryCode && categoryCode.value,
          popular
        })
      })

      const coupons = response.data.coupons

      if(coupons.length > 0) {
        let parsedArray = coupons.map((cp) => {
          let resObj = {
            ...cp,
            store: cp.store.name,
            category: cp.category.label
          }

          return resObj
        })

        setCoupons(parsedArray)
        setCouponLastPage(Math.ceil(response.data.totalCouponsCount / docsPerPage))
      } else{
        setCoupons([])
        setCouponLastPage(1)
      }
    } catch(err) {
      addToast(err.response.data.message, { 
        appearance: 'error',
        autoDismiss: true
      })
    }
  }

  const GetCouponById = async (couponId) => {
    try {
      if(!couponId) return;

      const response = await axios({
        method: 'GET',
        url: GetCouponByIdEndpoint + couponId,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json', 
        },
        withCredentials: true
      })

      setCoupon(response.data)
    } catch(err) {

    }
  }

  const UpdateCoupon = async (data) => {
    try {
      data = {
        ...data,
        score: parseInt(data.score),
        storeCode: data.storeCode.value,
        categoryCode: data.categoryCode.value
      }

      const response = await axios({
        method: 'POST',
        url: UpdateCouponEndpoint,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json', 
        },
        withCredentials: true,
        data: JSON.stringify(data)
      })

      addToast(response.data.message, { 
        appearance: 'success',
        autoDismiss: true
      })

      getTableCoupons(page)
    }catch(err){
      addToast(err.response.data.message, { 
        appearance: 'error',
        autoDismiss: true
      })
    }
  }

  return { PostCoupon, UpdateCoupon, GetCouponById, onSubmit,  setFiles, uploadPercentage, selectedFilesLength, setSelectedFilesLength, getTableCoupons, GetCouponsWithFilter }
}

export default useCoupon

