import React, { useEffect } from 'react'
import Modal from 'react-modal'
import { useForm } from "react-hook-form"
import { useToasts } from 'react-toast-notifications'
import axios from "axios"

import { InputWrapper, Input, Form, CheckBoxWrapper } from './addStoreModal.styled'
import Endpoints from '../../../../config/endpoints'

import useStore from '../../../../hooks/useStore'

const { GetLastStoreIdEndpoint } = Endpoints

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    maxHeight: '80%',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto'
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.55)'
  }
}

const AddStoreModal = ({ modalIsOpen, setIsOpen }) => {
  const { addToast } = useToasts()
  const { PostStore } = useStore(addToast)

  const { register, handleSubmit, formState: { errors }, setValue } = useForm()

  const GetLastStoreId = async () => {
    try {
      const response = await axios({
        method: 'GET',
        url: GetLastStoreIdEndpoint,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json', 
        },
        withCredentials: true
      })

      setValue('id', response.data.id + 1)
    } catch(err) {

    }
  }

  useEffect(() => {
      GetLastStoreId()
      setValue('name', "")
      setValue('code', "")
      setValue('description', "")
      setValue('heading', "")
      setValue('pheading', "")
      setValue('metaTitle', "")
      setValue('metaDescription', "")
      setValue('about', "")
      setValue('imageUrl', "")
      setValue('storeUrl', "")
      setValue('domain', "")
      setValue('background', "")
      setValue('active', true)
      setValue('popular', false)
      setValue('extensionOpen', false)
  }, [modalIsOpen])

  return <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => {setIsOpen(false)}}
            style={customStyles}
            contentLabel="Add Store"
          >
            <h2>Add Store</h2>
            
              <Form onSubmit={handleSubmit(PostStore)}>
              <InputWrapper>
                    <Input 
                      disabled
                      {...register("id", {
                        required: "Enter a coupon id"
                      })}
                    />
                    {errors.id && <p>{errors.id.message}</p>}
                </InputWrapper>

                <InputWrapper>
                  <Input 
                    autoComplete="off"
                    placeholder="Store name* (eg. Reliance Digital)" 
                    {...register("name", {
                      required: "Enter a store name"
                    })} 
                  />
                  {errors.name && <p>{errors.name.message}</p>}
                </InputWrapper>

                <InputWrapper>
                  <Input 
                    autoComplete="off"  
                    placeholder="Store code* (eg. reliance-digital)" 
                    {...register("code", {
                      required: "Enter a store code"
                    })} 
                  />
                  {errors.code && <p>{errors.code.message}</p>}
                </InputWrapper>

                <InputWrapper>
                  <Input 
                    autoComplete="off"  
                    placeholder="Store heading*" 
                    {...register("heading", {
                      required: "Enter a store heading"
                    })} 
                  />
                  {errors.heading && <p>{errors.heading.message}</p>}
                </InputWrapper>

                <InputWrapper>
                  <Input 
                    autoComplete="off"  
                    placeholder="Permanent offer Store heading*" 
                    {...register("pheading", {
                      required: "Enter permanent offer store heading"
                    })} 
                  />
                  {errors.pheading && <p>{errors.pheading.message}</p>}
                </InputWrapper>

                <InputWrapper>
                  <textarea 
                    autoComplete="off"
                    placeholder="Store Description*" 
                    {...register("description", {
                      required: "Enter a store description"
                    })} 
                  />
                  {errors.description && <p>{errors.description.message}</p>}
                </InputWrapper>

                <InputWrapper>
                  <textarea 
                    autoComplete="off"
                    placeholder="About store*" 
                    {...register("about", {
                      required: "Enter about the store"
                    })} 
                  />
                  {errors.about && <p>{errors.about.message}</p>}
                </InputWrapper>

                <InputWrapper>
                  <Input 
                    autoComplete="off"
                    placeholder="Image url" 
                    {...register("imageUrl", {
                      required: "Paste image url here"
                    })} 
                  />
                  {errors.imageUrl && <p>{errors.imageUrl.message}</p>}
                </InputWrapper>

                <InputWrapper>
                  <Input 
                    autoComplete="off"
                    placeholder="Store url (with affiliate)" 
                    {...register("storeUrl", {
                      required: "Paste store url here"
                    })} 
                  />
                  {errors.storeUrl && <p>{errors.storeUrl.message}</p>}
                </InputWrapper>

                <InputWrapper>
                  <Input 
                    autoComplete="off"
                    placeholder="Store domain" 
                    {...register("domain", {
                      required: "Paste store domain here"
                    })} 
                  />
                  {errors.domain && <p>{errors.domain.message}</p>}
                </InputWrapper>

                <InputWrapper>
                  <Input 
                    autoComplete="off"
                    placeholder="Meta title*" 
                    {...register("metaTitle", {
                      required: "Enter a meta title"
                    })} 
                  />
                  {errors.metaTitle && <p>{errors.metaTitle.message}</p>}
                </InputWrapper>

                <InputWrapper>
                  <Input 
                    autoComplete="off"
                    placeholder="Meta description*" 
                    {...register("metaDescription", {
                      required: "Enter a meta description"
                    })} 
                  />
                  {errors.metaDescription && <p>{errors.metaDescription.message}</p>}
                </InputWrapper>

                <InputWrapper>
                  <Input 
                    autoComplete="off"
                    placeholder="Background color*" 
                    {...register("background", {
                      required: "Background color*"
                    })} 
                  />
                  {errors.background && <p>{errors.background.message}</p>}
                </InputWrapper>

                <CheckBoxWrapper>
                  <div>
                    <input type="checkbox" defaultChecked {...register("active")}/>
                    <label>Active</label>  
                  </div>

                  <div>
                    <input type="checkbox" {...register("popular")}/>
                    <label>Popular</label>  
                  </div> 

                  <div>
                    <input type="checkbox" {...register("extensionOpen")}/>
                    <label>Extension</label>  
                  </div> 
                </CheckBoxWrapper>

                <input value="Add Store" type="submit"/>
              </Form>
            
          </Modal>
}

export default AddStoreModal


