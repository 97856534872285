import React, { useEffect } from "react"
import { useTable, useFlexLayout} from 'react-table'
import { useContext } from 'react'
import { useToasts } from 'react-toast-notifications'

import { Table, THead, TBody, TableRow, TableActionButton, GreenSignal, CellContainer, RedSignal } from "./bottomBannerTable.styled"

import { GetBannerImagesContext } from '../../../../context/BannerContext'

import useBanner from "../../../../hooks/useBanner"

const BottomBannersTable = () => {
  const { addToast } = useToasts()

  const { bottomBanners,  setBottomBannerId, setUpdateBottomModalIsOpen } = useContext(GetBannerImagesContext)
  const { BottomBannerTable } = useBanner(addToast)
  
  useEffect(() => {
    BottomBannerTable()
  }, [])

  const data = React.useMemo(() => bottomBanners, [bottomBanners])

  const columns = React.useMemo(() => [
    {
      Header: 'Image URL',
      accessor: 'imageUrl', // accessor is the "key" in the data
      Cell: ({row}) => {
        return row.original.imageUrl.split("https://assets.dealmela.com/home-bottom-banners/")[1]
      }
    },{
      Header: 'Position',
      accessor: 'position',
    },{
      Header: 'Active',
      Cell: ({row}) => {
        return <CellContainer>
          {row.original.active ? <GreenSignal /> : <RedSignal />}
        </CellContainer>
      }
    },
    {
      Header: 'Action',
      Cell: ({row}) => {
        return <TableActionButton onClick={() => {
          setBottomBannerId(row.original.id)
          setUpdateBottomModalIsOpen(true)
      }}>Edit</TableActionButton>
      }
    }
  ], [bottomBanners])

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data }, useFlexLayout)

  return <table {...getTableProps()} className="content-table">
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.length === 0 && <h3 style={{textAlign: "center", margin: '1rem 0'}}>No data found</h3>}
            {rows.map(row => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <td {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
}

export default BottomBannersTable