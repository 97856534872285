import React, { useEffect, useContext} from 'react'
import Modal from 'react-modal'
import { useForm } from "react-hook-form"
import { useToasts } from 'react-toast-notifications'

import { InputWrapper, Input,  Form, CheckBoxWrapper } from './updateCategoryModal.styled'

import useCategory from '../../../../hooks/useCategory'

import { GetTableValuesContext } from '../../../../context/CategoryContext'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    minHeight: '50%',
    display: 'flex',
    flexDirection: 'column'
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.55)'
  }
}

const UpdateCategoryModal = ({ updateModalIsOpen, setUpdateModalIsOpen }) => {
  const { addToast } = useToasts()

  const { register, handleSubmit, formState: { errors }, setValue } = useForm()

  const { GetCategoryById, UpdateCategory } = useCategory(addToast)


  const { categoryId, category } = useContext(GetTableValuesContext)

  useEffect(() => {
    GetCategoryById(categoryId)
  }, [categoryId])

  useEffect(() => {
    if(category) {
      const { id, label, code, description, imageUrl, active, metaTitle, metaDescription, popular } = category


      setValue('id', id)
      setValue('label', label)
      setValue('code', code)
      setValue('description', description)
      setValue('imageUrl', imageUrl)
      setValue('metaTitle', metaTitle)
      setValue('metaDescription', metaDescription)
      setValue('active', active)
      setValue('popular', popular)
    }
   }, [category])

  return <Modal
            isOpen={updateModalIsOpen}
            onRequestClose={() => {setUpdateModalIsOpen(false)}}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="Update Category"
          >
            <h2>Update Category</h2>
            {!category ? <p>Loading..</p> :            
              <Form onSubmit={handleSubmit(UpdateCategory)}>
                <InputWrapper>
                    <Input 
                      disabled
                      {...register("id", {
                        required: "Enter a category id"
                      })}
                    />
                    {errors.id && <p>{errors.id.message}</p>}
                </InputWrapper>

                <InputWrapper>
                  <Input 
                    autoComplete="off"
                    placeholder="Category Label* (eg. Reliance Digital)" 
                    {...register("label", {
                      required: "Enter a category label"
                    })} 
                  />
                  {errors.label && <p>{errors.label.message}</p>}
                </InputWrapper>

                <InputWrapper>
                  <Input 
                    autoComplete="off"  
                    placeholder="Category code* (eg. reliance-digital)" 
                    {...register("code", {
                      required: "Enter a Category code"
                    })} 
                  />
                  {errors.code && <p>{errors.code.message}</p>}
                </InputWrapper>

                <InputWrapper>
                  <textarea 
                    autoComplete="off"
                    placeholder="Category Description*" 
                    {...register("description", {
                      required: "Enter a Category description"
                    })} 
                  />
                  {errors.description && <p>{errors.description.message}</p>}
                </InputWrapper>

                <InputWrapper>
                  <Input 
                    autoComplete="off"
                    placeholder="Image url" 
                    {...register("imageUrl", {
                      required: "Paste image url here"
                    })} 
                  />
                  {errors.imageUrl && <p>{errors.imageUrl.message}</p>}
                </InputWrapper>

                <InputWrapper>
                  <Input 
                    autoComplete="off"
                    placeholder="Meta title*" 
                    {...register("metaTitle", {
                      required: "Enter a meta title"
                    })} 
                  />
                  {errors.metaTitle && <p>{errors.metaTitle.message}</p>}
                </InputWrapper>

                <InputWrapper>
                  <Input 
                    autoComplete="off"
                    placeholder="Meta description*" 
                    {...register("metaDescription", {
                      required: "Enter a meta description"
                    })} 
                  />
                  {errors.metaDescription && <p>{errors.metaDescription.message}</p>}
                </InputWrapper>

                <CheckBoxWrapper>
                  <div>
                    <input type="checkbox" {...register("active")}/>
                    <label>Active</label>  
                  </div>

                  <div>
                    <input type="checkbox" {...register("popular")}/>
                    <label>Popular</label>  
                  </div> 
                </CheckBoxWrapper>

                <input value="Update category" type="submit"/>
              </Form>}
          </Modal>
}

export default UpdateCategoryModal

